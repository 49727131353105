.nav-tabs li a.has-error {
    background: rgba(231, 76, 60, .4);
    border-color: #e74c3c;
    border-bottom: none;
}

.publish-unpublish-ajax:hover,
.publish-unpublish-ajax:focus,
.publish-unpublish-ajax:active {
    text-decoration: none;
}

.panel-body-heading .pb-watermark {
    bottom: 0;
    font-size: 50px;
}

.panel-widget .panel-body-heading .pb-watermark {
    margin-bottom: -10px;
    padding-right: 5px;
}

.side-body {
    overflow: auto;
}

.side-nav > ul > .side-nav-item-heading {
    display: none;
}

.nav-item-icon {
    margin-top: 0;
    font-size: 22px;
}

.dd-list > li {
    margin-bottom: 10px;
}

.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 48px;
    font-size: 13px;
    line-height: 20px;
}

.dd-content .panel-title {
    line-height: 25px;
}

.select2-container .select2-choice {
    margin: 0 !important;
}

.select2-container .select2-choice > .select2-chosen {
    line-height: 34px;
}

.select2-container .select2-choice .select2-arrow {
    padding-top: 3px;
}

.pagination {
    margin: 0;
    display: block;
}

.pagination li.active.disabled a,
.pagination li.active.disabled a:hover {
    color: white;
}

.pagination li.active.disabled span {
    background: #ffff;
}

.pagination li.disabled span,
.pagination li.active.disabled span {
    background: #F5F5F5;
    border-color: #ddd;
}

.dropdown-menu.dropdown-extend > li > a {
    padding: 6px 15px;
}

.panel-transparent {
    border: 0;
    background: transparent;
}

.panel-transparent > .panel-heading .nav-tabs li:not(.active) a {
    color: #585757;
}

.panel-transparent > .panel-body {
    border: 1px solid #ddd;
    border-top: 0;
    background: white;
}


.form-group > .form-group
{
    margin-bottom: 0;
}

.header-actions .dropdown-menu {
    max-height: 500px;
    overflow: auto;
}

.header-actions .dropdown-menu li small {
    float: right;
    line-height: 20px;
}

#toggle-config-menu {
    display: none;
}

@media only screen and (max-width: 767px) {
    .breadcrumb.hide-xs {
        display: none;
    }

    .header-actions.pull-right {
        width: 100%;
        position: absolute;
        height: inherit;
    }

    #toggle-config-menu {
        float: right;
        height: 100%;
        z-index: 10;
    }

    .header-actions.pull-right .header-actions-config {
        position: absolute;
        right: -500px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
        width: 100%;
        height: 60px;
    }

    .header-actions.pull-right .header-actions-config > ul {
        padding: 0;
        width: 100%;
    }

    .header-actions.pull-right .header-actions-config.active {
        right: 0;
        background: #3498db;
    }

    .header-actions.pull-right .header-actions-config .btn-group {
        position: static;
    }

    .header-actions.pull-right .header-actions-config .btn-group .dropdown-menu.pull-right {
        left: 0;
        right: 0;
        width: 100%;
        max-width: 100%;
    }

    #toggle-config-menu {
        display: block;
    }
}

.black-container {
    overflow: hidden;
    background-color: #000;
}

.signin,
.signup,
.recover {
    background: url('/bundles/siqualadmin/images/bg-innopolis.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.sign-brand-logo {
    padding: 40px 0;
}

.sign-brand-logo img {
    height: inherit;
    width: inherit;
    max-width: 250px;
    max-height: 100px;
}

.signin a,
.signup a,
.recover a {
    color: white;
}

.sign-footer,
.sign-footer a {
    z-index: 2;
    color: white;
}

/*siqual*/
.siqual_translationsLocales li.active > a,
.siqual_translationsLocales li.active > a:hover,
.siqual_translationsLocales li.active > a:focus {
    background: #F5F5F5;
}

.siqual_translationsLocales.nav-tabs {
    border: 0;
}

.siqual_translationsFields.tab-content {
    overflow: auto;
    margin: 0;
    background: #F5F5F5;
    border: 1px solid #ddd;
    padding: 20px 15px 0 15px;
}

.side-header .brand .sitename {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.side-header .icon-external-link {
    position: absolute;
    top: 0;
    right: 12px;
}

a.sorting-link i {
    text-decoration: none !important;
}

a.sorting-link .icon-hover {
    display: none;
}

a.sorting-link:hover .icon-hover {
    display: inline-block;
}

a.sorting-link:hover .icon-sorting {
    display: none;
}

body:not(.black-container) #footer {
    bottom: 0;
    right: 0;
    top: inherit;
    display: block;
    position: fixed;
    z-index: 5;
    text-align: center;
    background: white;
    font-size: 12px;
    padding: 3px 5px 2px 5px;
    border-radius: 5px 0 0 0;
}

@media (max-width: 991px) {
    #content {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 767px) {
    .app-header {
        min-height: inherit;
        padding: 5px;
    }

    .form-actions .btn {
        width: 100%;
        margin-bottom: 10px;
    }
}

.modal-backdrop, .modal-backdrop.fade.in {
    opacity: 0.6;
    filter: alpha(opacity=60);
    background: #000;
}

.modal-footer {
    padding: 0;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
}

.modal-footer > * {
    display: table-cell;
    width: 100%;
    border-radius: 0;
    border: 0;
    margin: 0!important;
}

.btn span.hover-expand {
    width: 0;
    opacity: 0;
    display: none;
}

.btn:hover span.hover-expand {
    width: auto;
    opacity: 1;
    display: inline-block;
}

@media (max-width: 768px) {
    .btn span.hover-expand {
        width: auto;
        opacity: 1;
        display: inline-block;
    }

    .siqual_admin-list-actions .btn,
    .st-val .btn,
    .st-val .btn-group,
    .btn[data-target="#modal-confirm-batch-actions"] {
        display: block;
        margin-bottom: 5px;
        width: 100%;
    }

    .app-header [class*="col-"] {
        padding-left: 2px;
        padding-right: 2px;
    }

    .form-search [class*="col-xs-"] {
        padding: 0 2px;
    }

    .app-header .pull-right {
        float: none!important;
    }
    .app-header .col-md-4 {
        padding: 0!important;
    }

    .dropdown-menu>li>a {
        white-space: inherit!important;
    }

    .nav-pills>li {
        width: 49%;
        text-align: center;
    }

    .nav-pills>li:nth-child(2n+1) {
        clear: left;
    }

    .unstackable td .btn-block {
        width: calc(50% - 4px);
        float: left;
        margin: 2px;
    }
}

.row {
    margin-bottom: 0;
}

.btn {
    font-weight: 300;
}