/**
* main-tablet.css v1.1
* Syrena admin template
*
* License:
* For License Information check out - https://wrapbootstrap.com
*
* Copyright 2014, Stilearning
* http://stilearning.com
*/

/* Landscape phone to portrait tablet */
@media (max-width: 768px) {

    /**
     * Theme Split Layout
     * We use fixe mode layout width every section inside wrapper
     */
    .content {
        padding-left: 0;

        -ms-transform: translateX(250px);
        -webkit-transform: translateX(250px);
        -moz-transform: translateX(250px);
        transform: translateX(250px);

        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        z-index: 3;
    }

    .content.content-lg {
        padding-left: 0;

        -ms-transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        transform: translateX(0);

    }

    .content-main.content-main-md {
        right: -1px;

        -ms-transform: translateX(-250px);
        -webkit-transform: translateX(-250px);
        -moz-transform: translateX(-250px);
        transform: translateX(-250px);
    }

    /* Gallery page */
    .gallery-item {
        width: 50%;
    }

    .gallery-item-caption {
        left: 40px;
        right: 0;
    }

    .gallery-item-caption.open {
        -webkit-transform: translateX(-250px);
        -moz-transform: translateX(-250px);
        -ms-transform: translateX(-250px);
        -o-transform: translateX(-250px);
        transform: translateX(-250px);
    }

    .gi-caption-close {
        right: 60px;
    }

    .gi-toggle-details {
        display: block;
    }

    .gallery-item-caption .panel {
        -webkit-transform: translateX(250px);
        -moz-transform: translateX(250px);
        -ms-transform: translateX(250px);
        -o-transform: translateX(250px);
        transform: translateX(250px);
    }

    /* Gallery page */
    /**
     * Visibility class
     */
    .view-lg {
        display: inline;
    }

    /* visible from 1280  */
    .view-md {
        display: inline;
    }

    /* visible from 767  */
    .view-sm {
        display: none;
    }

    /* visible from 480  */

}
